<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Header from "./components/header";
import Footer from "./components/footer";
// import global from "./assets/js/global.js";

export default {
  components: {
    Header,
    Footer,
  },
  mounted() {
    // global();
  },
  watch: {
    // $route: function (to, from) {
    //   global();
    // },
  },
};
</script>
<style>
</style>
