export default function () {
    gsap.registerPlugin(ScrollTrigger, SplitText)

    // clamp(0.667px, calc(0.035vw + 0.334px), 1px)
    // Step 1: Get the viewport width in pixels
    let vw = window.innerWidth / 100;

    // Step 2: Calculate the mid value
    let midValue = 0.035 * vw + 0.334;

    // Step 3: Clamp the value between 0.667px and 1px
    let clampedValue = Math.min(Math.max(midValue, 0.667), 1);

    // Step 4: Multiply the clamped value by 80
    let finalValue = 80 * clampedValue;


    let windowW = window.innerWidth;
    let pcState = windowW > 768;
    let secMain = 'bottom bottom'

    if (!pcState) {

        secMain = 'top center'
    }

    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.sec1 .main',
            start: 'top 80%',
            end: secMain,
            scrub: 1
        }
    });

    let $_sec1Title = new SplitText($('.sec1 .common-title'), { type: 'chars, words, lines' });
    let $_sec1Text = new SplitText($('.sec1 .text'), { type: 'lines' });

    // number
    var numAnim1 = new countUp.CountUp('num-1', $('#num-1').text());
    var numAnim2 = new countUp.CountUp('num-2', $('#num-2').text());
    var numAnim3 = new countUp.CountUp('num-3', $('#num-3').text());
    var numAnim4 = new countUp.CountUp('num-4', $('#num-4').text());
    // numAnim.start()

    gsap.set($_sec1Title.chars, { y: 50, opacity: 0 })
    gsap.set($_sec1Text.lines, { y: 50, opacity: 0 })
    gsap.set($('.sec1 .learn-more'), { y: 50, opacity: 0 })
    gsap.set($('.sec1 .data'), { y: 50, opacity: 0 })


    tl
        .to($_sec1Title.chars, {
            y: 0,
            opacity: 1,
            stagger: .1
        })
        .to($_sec1Text.lines, {
            y: 0,
            opacity: 1,
            stagger: .1
        })
        .to($('.sec1 .learn-more'), {
            y: 0,
            opacity: 1,
        })
        .to($('.sec1 .data'), {
            y: 0,
            opacity: 1,
            onStart: () => {
                numAnim1.start();
                numAnim2.start();
                numAnim3.start();
                numAnim4.start();
            }
        })

    // three.js --start
    var scene = new THREE.Scene();

    var width = window.innerWidth;
    var height = window.innerHeight;
    var aspect = width / height;
    var halfViewSize = 32; // 根据透视相机的fov值来估算一个大致相等的视野大小

    var camera = new THREE.PerspectiveCamera(10, window.innerWidth / window.innerHeight, 0.1, 1000);

    camera.position.z = 600;

    var renderer = Detector.webgl ? new THREE.WebGLRenderer({ antialias: true }) : new THREE.CanvasRenderer();

    var cylinderColor = '#c6f0fc';
    var purple = '#c6f0fc';

    renderer.setSize(window.innerWidth * 1.4, window.innerHeight * 1.4);
    const el = document.getElementsByClassName('circle-dna')[0];

    el.appendChild(renderer.domElement);

    var tubeGeometry = new THREE.CylinderGeometry(0.05, 0.05, 15.8, 32);
    var ballGeometry = new THREE.SphereGeometry(.85, 32, 32);

    var cylinderMaterial = new THREE.MeshBasicMaterial({ color: cylinderColor });
    var purpleMaterial = new THREE.MeshBasicMaterial({ color: purple });

    var dna = new THREE.Object3D();
    var holder = new THREE.Object3D();

    if (width < 1000) {
        var totalRows = 50;
    } else {
        var totalRows = 90;
    }

    var angleIncrement = (2 * Math.PI) / totalRows;

    for (var i = 0; i < totalRows; i++) {
        var cylinder = new THREE.Mesh(tubeGeometry, cylinderMaterial);
        cylinder.rotation.z = 90 * Math.PI / 180;
        cylinder.position.x = 0;

        var ballRight = new THREE.Mesh(ballGeometry, new THREE.MeshBasicMaterial({ color: '#c0e3f9' }));
        ballRight.position.x = 7;

        var ballLeft = new THREE.Mesh(ballGeometry, purpleMaterial);
        ballLeft.position.x = -7;

        var row = new THREE.Object3D();

        row.add(cylinder);
        row.add(ballRight);
        row.add(ballLeft);

        var radius = 37;
        // var x = radius * Math.cos(i * angleIncrement) * 1.2;
        // var y = radius * Math.sin(i * angleIncrement) * 1.2;

        if (width < 1000) {
            var x = radius * Math.cos(i * angleIncrement) * .7;
            var y = radius * Math.sin(i * angleIncrement) * .7;
        } else {
            var x = radius * Math.cos(i * angleIncrement) * 1.2;
            var y = radius * Math.sin(i * angleIncrement) * 1.2;
        }
        row.position.set(x, y, 0);

        // 计算切线方向，即沿圆的切线方向移动一点
        var tangent = new THREE.Vector3(-y, x, 0).normalize();
        var targetPoint = new THREE.Vector3().copy(row.position).add(tangent);

        // 使用lookAt方法设置对象朝向目标点
        row.lookAt(targetPoint);
        row.rotation.z = Math.PI * 2 * 6 / totalRows * i;

        dna.add(row);
    }

    scene.add(dna);

    holder.rotation.z = Math.PI / 4 * 3 * .95;

    var render = function () {
        //holder.rotation.x += 0.00;
        dna.rotation.z += 0.002;
        updateDnaRow();
        requestAnimationFrame(render);

        renderer.render(scene, camera);
    }

    function updateDnaRow() {
        dna.children.forEach(function (row, index) {
            // 每一行的旋转角度增加一定量
            row.rotation.z += 0.008;
        });
    }

    render();

    // 监听窗口大小变化事件
    window.addEventListener('resize', function () {
        var width = window.innerWidth * 1.4;
        var height = window.innerHeight * 1.4;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
    }, false);
    // three.js --end

    tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.sec1 .circle-dna',
            // pin: true,
            start: 'top center',
            end: '+=100%',
            scrub: 1,
            // pinSpacing: false,
            ease: 'none',
        }
    });

    if (!pcState) {
        tl
            .to('.sec1 .circle-dna canvas', { scale: 1 })
    } else {
        tl
            .to('.sec1 .circle-dna canvas', { scale: 1.2 })
    }


    let secEnd = '+=100%';

    if (windowW < 1000) {
        secEnd = '+=50%';
    }
    if (windowW < 768) {
        secEnd = 'top 30%';
    }

    sec1An(finalValue)
    function sec1An(hParam) {
        let secTl = gsap.timeline({
            scrollTrigger: {
                trigger: '.sec1',
                pin: pcState,
                start: 'top ' + hParam,
                end: secEnd,
                scrub: 1,
                pinSpacing: false,
                ease: 'none'
            }
        });

        if (windowW > 760) {
            secTl
                .to('.sec1 .main', {
                    yPercent: -100
                }, '+=20%')
        }

    }

    let $_sec2Title = new SplitText($('.sec2 .common-title'), { type: 'chars, words, lines' });
    gsap.set($_sec2Title.chars, { xPercent: 100, opacity: 0 })

    tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.sec2 .common-title',
            start: 'top 80%',
            end: 'bottom center',
            scrub: 1
        }
    });

    tl
        .to($_sec2Title.chars, {
            xPercent: 0,
            opacity: 1,
            stagger: .1,
        })

    tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.sec2',
            start: 'top 80%',
            end: 'top 20%',
            scrub: 1
        }
    });

    gsap.set('.sec2 .left-color-bg', { yPercent: 100, opacity: 0 })
    gsap.set('.sec2 .container .left', { yPercent: 100, opacity: 0 })
    gsap.set('.sec2 .container .right', { yPercent: 100, opacity: 0 })
    gsap.set('.sec2 .container .bg', { yPercent: 50, opacity: 0 })


    tl
        .to('.sec2 .container .bg', {
            yPercent: 0,
            opacity: 1
        })
        .to('.sec2 .container .right', {
            yPercent: 0,
            opacity: 1
        }, '-=90%')
        .to('.sec2 .container .left', {
            yPercent: 0,
            opacity: 1
        }, '-=95%')
        .to('.sec2 .left-color-bg', {
            yPercent: 0,
            opacity: 1
        }, '<')


    tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.index-sec3 .label',
            start: 'top 80%',
            end: 'top 60%',
            scrub: 1,
        }
    });

    let $_newsTitle = new SplitText($('.index-sec3 .label'), { type: 'chars, words, lines' });
    gsap.set($_newsTitle.chars, { xPercent: 100, opacity: 0 })
    gsap.set('.index-sec3 .container', { yPercent: 50, opacity: 0 })
    gsap.set('.index-sec3 .learn-more', { opacity: 0, yPercent: 100 })

    tl
        .to($_newsTitle.chars, {
            xPercent: 0,
            opacity: 1,
            stagger: .1,
        })

    tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.index-sec3',
            start: 'top 70%',
            end: 'top 40%',
            scrub: 1,
        }
    });

    tl
        .to('.index-sec3 .container', {
            yPercent: 0,
            opacity: 1
        })
        .to('.index-sec3 .learn-more', {
            opacity: 1,
            yPercent: 0
        }, '80%')

    let $_circleArr = $('.knowledge-center-bg svg circle');
    gsap.set($_circleArr, { opacity: 0 })

    $_circleArr.each((i, item) => {
        let tl = gsap.timeline();
        tl
            .to($(item), { opacity: 1, delay: i * 1.38 })
            .to($(item), {
                opacity: 0, transformOrigin: 'center center', scale: 2.8, repeat: -1, duration: 10, ease: 'none'
            })
    })

    gsap.set('knowledge-center-bg', { xPercent: 50, yPercent: 50 })
    gsap.to('.knowledge-center-bg .s-knowledge', { scale: .8, duration: 1.9, repeat: -1, yoyo: true })

    let $_knowledgeTitle = new SplitText($('.sec4 .common-title'), { type: 'chars, words, lines' });

    tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.sec4 .common-title',
            start: 'top 80%',
            end: 'bottom center',
            scrub: 1,
        }
    });

    gsap.set($_knowledgeTitle.chars, { xPercent: 100, opacity: 0 })
    tl
        .to($_knowledgeTitle.chars, {
            xPercent: 0,
            opacity: 1,
            stagger: .1,
        })

    tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.sec4 .main',
            start: 'top 90%',
            end: 'top 40%',
            scrub: 1,
        }
    });

    gsap.set('.sec4 .main .container', { yPercent: 100, opacity: 0 })
    gsap.set('.knowledge-center-bg .s-knowledge', { yPercent: -50, xPercent: -50 })

    tl
        .to('.sec4 .main .container', { yPercent: 0, opacity: 1 })


    tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.sec6',
            start: 'top 80%',
            end: 'bottom top',
            scrub: 1,
            invalidateOnRefresh: true
        }
    });

    tl
        .to('.knowledge-center-bg svg', { scale: 1.5 })
        .to('.knowledge-center-bg .b-knowledge', { scale: 1.5 }, '<')
        .to('.knowledge-center-bg .s-knowledge', { scale: 1.5 }, '<')

    let $_sec6Title = new SplitText($('.sec6-t .common-title .label'), { type: 'chars, words, lines' });

    tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.sec6-t .common-title .label',
            start: 'top 80%',
            end: 'bottom center',
            scrub: 1,
        }
    });

    gsap.set($_sec6Title.chars, { xPercent: 100, opacity: 0 })
    gsap.set('.sec6-t .btn', { yPercent: 100, opacity: 0 })
    tl
        .to($_sec6Title.chars, {
            xPercent: 0,
            opacity: 1,
            stagger: .1,
        })
        .to('.sec6-t .btn', { yPercent: 0, opacity: 1 })


    let $_sec7Title = new SplitText($('.sec7 .common-title .label'), { type: 'chars, words, lines' });

    tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.sec7 .common-title .label',
            start: 'top 80%',
            end: 'bottom center',
            scrub: 1,
        }
    });

    gsap.set($_sec7Title.chars, { xPercent: 100, opacity: 0 })
    gsap.set('.sec7 .btn', { yPercent: 100, opacity: 0 })

    tl
        .to($_sec7Title.chars, {
            xPercent: 0,
            opacity: 1,
            stagger: .1,
        })
        .to('.sec7 .btn', {
            yPercent: 0,
            opacity: 1
        })

    tl = gsap.timeline({
        scrollTrigger: {
            trigger: '.sec7',
            start: 'top 80%',
            end: 'bottom center',
            scrub: 1,
        }
    });

    tl
        .to('.sec7', { backgroundSize: '100%' })


}

// $(function () {


// })