export default function(wi, el) {
  const complete = el.find('.complete')[0];
  const circle = el.find('.ring')[0];
  var elementLeft, elementWidth, elementHeight, elementTop, getData = true;
  // complete.style.cursor = 'none';
  // circle.style.cursor = 'none';
  function moveCircle(event) {
    event.stopPropagation()
    var elementRect = complete.getBoundingClientRect();
    if (getData) {
      getData = false
      elementLeft = elementRect.left;
      elementTop = elementRect.top;
      elementWidth = elementRect.width;
      elementHeight = elementRect.height;
    }

    var mouseX = event.clientX;
    var mouseY = event.clientY;
    var distanceLeft = mouseX - elementLeft;
    var distanceTop = mouseY - elementTop;
    if (distanceLeft > 0 && distanceLeft < elementWidth || distanceTop > 0 && distanceTop < elementHeight) {
      circle.style.display = 'flex'
      circle.style.clipPath = 'circle(' + wi + 'px at ' + distanceLeft + 'px ' + distanceTop + 'px)'
    }
  }
  complete.addEventListener('mousemove', moveCircle, true);
  circle.addEventListener('mousemove', moveCircle, true);
  // $(window).on('resize', function () {
  //   document.querySelector('.complete').removeEventListener('mousemove', moveCircle, true);
  //   document.querySelector('.ring').removeEventListener('mousemove', moveCircle, true);
  //   endoscopy(120 / 1920 * window.innerWidth, $('.footer-intro'))
  //   endoscopy(240 / 1920 * window.innerWidth, $('.banner'))
  // })
}
