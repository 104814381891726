export default function (el, ringPosition) {
  var container = el
  var scene = new THREE.Scene();


  var width = container.innerWidth();
  var height = container.innerHeight();
  var aspect = width / height;
  var halfViewSize = 32; // 根据透视相机的fov值来估算一个大致相等的视野大小

  var camera = new THREE.PerspectiveCamera(10, width / height, 0.1, 1000);

  camera.position.z = 360;
  if (!Detector.webgl) Detector.addGetWebGLMessage();
  var renderer = Detector.webgl ? new THREE.WebGLRenderer({ antialias: true }) : new THREE.CanvasRenderer();

  var cylinderColor = 0x77CDE2;
  var purple = 'rgb(32,105,220)';

  renderer.setSize(width, height);

  container.append(renderer.domElement);

  var tubeGeometry = new THREE.CylinderGeometry(0.05, 0.05, 10.8, 32);
  var ballGeometry = new THREE.SphereGeometry(.5, 32, 32);

  var cylinderMaterial = new THREE.MeshBasicMaterial({ color: cylinderColor });
  var purpleMaterial = new THREE.MeshBasicMaterial({ color: purple });

  var dna = new THREE.Object3D();
  var holder = new THREE.Object3D();

  var totalRows = ringPosition.totalRows;
  var rowGap = 1.6;

  function gradientColor(startColor, endColor, steps) {
    // 提取颜色值的RGB部分
    var startRGB = startColor.substring(1).match(/.{2}/g)
      .map(function (hex) { return parseInt(hex, 16) });
    var endRGB = endColor.substring(1).match(/.{2}/g)
      .map(function (hex) { return parseInt(hex, 16) });

    // 确保颜色值的个数正确
    if (startRGB.length !== 3 || endRGB.length !== 3) {
      throw new Error('颜色值应该有3个数字，分别表示RGB的分量');
    }

    // 确保步数是正数
    if (steps <= 0) {
      throw new Error('步数应该是一个正数');
    }

    var stepsRGB = [];

    for (var i = 0; i <= steps; i++) {
      var step = i / steps;
      var r = Math.floor(startRGB[0] * (1 - step) + endRGB[0] * step);
      var g = Math.floor(startRGB[1] * (1 - step) + endRGB[1] * step);
      var b = Math.floor(startRGB[2] * (1 - step) + endRGB[2] * step);
      stepsRGB.push('rgb(' + r + ',' + g + ',' + b + ')');
    }

    return stepsRGB;
  }

  var gradientColors
  // 渐变色
  gradientColors = gradientColor('#58E9FE', '#2574DF', totalRows);

  function setCylinderMaterial(i) {
    return new THREE.MeshBasicMaterial({ color: gradientColors[i] });
  }
  for (var i = 0; i < totalRows; i++) {
    // var cylinder = new THREE.Mesh(tubeGeometry, cylinderMaterial);
    var cylinder = new THREE.Mesh(tubeGeometry, setCylinderMaterial(i));
    cylinder.rotation.z = 90 * Math.PI / 180;
    cylinder.position.x = 0;

    // var ballRight = new THREE.Mesh(ballGeometry, purpleMaterial);
    var ballRight = new THREE.Mesh(ballGeometry, setCylinderMaterial(i));
    ballRight.position.x = 5;

    // var ballLeft = new THREE.Mesh(ballGeometry, purpleMaterial);
    var ballLeft = new THREE.Mesh(ballGeometry, setCylinderMaterial(i));
    ballLeft.position.x = -5;

    var row = new THREE.Object3D();

    row.add(cylinder);
    row.add(ballRight);
    row.add(ballLeft);

    row.position.set(ringPosition.x, (i - totalRows / 2) * rowGap + ringPosition.y, ringPosition.z);

    row.rotation.y = i * 0.36;

    dna.add(row);
  }

  dna.rotation.z = Math.PI * .72;
  scene.add(dna);

  // var takeScreenshotCounter = 2;
  var render = function () {
    //holder.rotation.x += 0.00;
    //dna.rotation.z += 0.002;
    updateDnaRow();
    requestAnimationFrame(render);

    renderer.render(scene, camera);

    // if (--takeScreenshotCounter <= 0) {
    //   console.log(1);
    //   // 生成canvas图片
    //   var canvas = renderer.domElement;
    //   var dataURL = canvas.toDataURL('image/png');

    //   // 创建a标签并插入页面
    //   var a = document.createElement('a');
    //   a.href = dataURL;
    //   a.download = 'screenshot_' + new Date().getTime() + '.webp'; // 设置下载的文件名
    //   document.body.appendChild(a);
    //   a.click();
    //   document.body.removeChild(a);

    //   // 重置计数器，每隔2帧再生成一次
    //   takeScreenshotCounter = 2;
    // }

  }

  function updateDnaRow() {
    dna.children.forEach(function (row, index) {
      // 每一行的旋转角度增加一定量
      row.rotation.y += 0.008;
    });
  }

  render();

  // 监听窗口大小变化事件
  window.addEventListener('resize', function () {
    var container = el
    var width = container.innerWidth();
    var height = container.innerHeight();
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
  }, false);
}
