<template>
  <div>
    <div class="site-header">
      <div class="site-header-sample min"></div>
      <div class="site-header-bar">
        <nav class="top-nav">
          <div class="top-nav-item full">
            <router-link :to="{ path: '/about' }" class="top-nav-link">
              <span class="display">About</span>
              <span class="placeholder">About</span>
            </router-link>
            <div class="top-nav-sub top-nav-sub-layout" data-index="3">
              <div class="top-nav-cont">
                <router-link
                  :to="{ path: '/about', query: { target: 0 } }"
                  class="top-nav-cont-item"
                >
                  <div class="pic">
                    <img src="../assets/images/icon8.png" alt="" />
                  </div>
                  <div class="txt">Our Company</div>
                </router-link>
                <router-link
                  :to="{ path: '/about', query: { target: 1 } }"
                  class="top-nav-cont-item"
                >
                  <div class="pic">
                    <img src="../assets/images/icon9.png" alt="" />
                  </div>
                  <div class="txt">Our DNA</div>
                </router-link>
                <router-link
                  :to="{ path: '/about', query: { target: 2 } }"
                  class="top-nav-cont-item"
                >
                  <div class="pic">
                    <img src="../assets/images/icon10.png" alt="" />
                  </div>
                  <div class="txt">Our Facility</div>
                </router-link>
                <router-link
                  :to="{ path: '/about', query: { target: 3 } }"
                  class="top-nav-cont-item"
                >
                  <div class="pic">
                    <img src="../assets/images/icon11.png" alt="" />
                  </div>
                  <div class="txt">Our History</div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="top-nav-item full">
            <router-link to="/modalities" class="top-nav-link">
              <span class="display">Modalities</span>
              <span class="placeholder">Modalities</span>
            </router-link>
            <div class="top-nav-sub top-nav-sub-layout" data-index="1">
              <div class="top-nav-cont">
                <router-link
                  :to="{ path: '/modalities/ov', query: { id: 0 } }"
                  class="top-nav-cont-item"
                >
                  <div class="pic">
                    <img
                      src="../assets/images/icon-1-1.svg"
                      alt=""
                      data-type="notchange"
                    />
                  </div>
                  <div class="txt">Oncolytic Virus</div>
                </router-link>
                <router-link
                  :to="{ path: '/modalities/mc', query: { id: 1 } }"
                  class="top-nav-cont-item"
                >
                  <div class="pic">
                    <img
                      src="../assets/images/icon-3-1.svg"
                      alt=""
                      data-type="notchange"
                    />
                  </div>
                  <div class="txt">mRNA</div>
                </router-link>
                <router-link
                  :to="{ path: '/modalities/ic', query: { id: 2 } }"
                  class="top-nav-cont-item"
                >
                  <div class="pic">
                    <img
                      src="../assets/images/icon-2-1.svg"
                      alt=""
                      data-type="notchange"
                    />
                  </div>
                  <div class="txt">iPSC</div>
                </router-link>
                <router-link
                  :to="{ path: '/modalities/vv', query: { id: 3 } }"
                  class="top-nav-cont-item"
                >
                  <div class="pic">
                    <img
                      src="../assets/images/icon-4-1.svg"
                      alt=""
                      data-type="notchange"
                    />
                  </div>
                  <div class="txt">Viral Vector</div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="top-nav-item full">
            <router-link to="/services" class="top-nav-link">
              <span class="display">Services</span>
              <span class="placeholder">Services</span>
            </router-link>
            <div
              class="top-nav-sub top-nav-sub-layout top-nav-sub-large"
              data-index="2"
            >
              <div class="top-nav-cont">
                <router-link to="/services/dp" class="top-nav-cont-item">
                  <div class="pic">
                    <img src="../assets/images/icon3.png" alt="" />
                  </div>
                  <div class="txt">Research & Preclinical</div>
                </router-link>
                <router-link to="/services/pd" class="top-nav-cont-item">
                  <div class="pic">
                    <img src="../assets/images/icon4.png" alt="" />
                  </div>
                  <div class="txt">Process Development</div>
                </router-link>
                <router-link to="/services/ad" class="top-nav-cont-item">
                  <div class="pic">
                    <img src="../assets/images/icon5.png" alt="" />
                  </div>
                  <div class="txt">Analytical Development</div>
                </router-link>
                <router-link to="/services/cp" class="top-nav-cont-item">
                  <div class="pic">
                    <img src="../assets/images/icon6.png" alt="" />
                  </div>
                  <div class="txt">cGMP Production</div>
                </router-link>
                <router-link to="/services/qc" class="top-nav-cont-item">
                  <div class="pic">
                    <img src="../assets/images/icon7.png" alt="" />
                  </div>
                  <div class="txt">Quality & Compliance</div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="top-nav-item full">
            <router-link to="/news_knowledge" class="top-nav-link">
              <span class="display">News & Knowledge Center</span>
              <span class="placeholder">News & Knowledge Center</span>
            </router-link>

            <div
              class="top-nav-sub top-nav-sub-layout top-nav-sub-large"
              data-index="4"
            >
              <div class="top-nav-cont">
                <router-link
                  to="/news_knowledge/news"
                  class="top-nav-cont-item"
                >
                  <div class="pic">
                    <img
                      src="../assets/images/new_icon-2.svg"
                      alt=""
                      data-type="notchange"
                    />
                  </div>
                  <div class="txt">News</div>
                </router-link>
                <router-link
                  to="/news_knowledge/knowledge_center"
                  class="top-nav-cont-item"
                >
                  <div class="pic">
                    <img
                      src="../assets/images/new_icon-1.svg"
                      alt=""
                      data-type="notchange"
                    />
                  </div>
                  <div class="txt">Knowledge Center</div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="top-nav-item">
            <router-link to="/join" class="top-nav-link">
              <span class="display">Join</span>
              <span class="placeholder">Join</span>
            </router-link>
          </div>
          <div class="top-nav-item">
            <router-link to="/contacts" class="top-nav-link">
              <span class="display">Contacts</span>
              <span class="placeholder">Contacts</span>
            </router-link>
          </div>
        </nav>
        <div class="btn-search-top top-button">
          <!-- <img src="../assets/images/icon_search.svg" alt="" class="open-search icon"> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="21.248169"
            height="20.707031"
            viewBox="0 0 21.2482 20.707"
            fill="none"
            class="open-search icon"
          >
            <defs></defs>
            <path
              id="Vector"
              d="M8.32 16.64C12.92 16.64 16.64 12.92 16.64 8.32C16.64 3.72 12.92 0 8.32 0C3.72 0 0 3.72 0 8.32C0 12.92 3.72 16.64 8.32 16.64ZM9.95 15.46C9.42 15.58 8.88 15.64 8.32 15.64C7.76 15.64 7.22 15.58 6.69 15.46C6.27 15.37 5.87 15.24 5.47 15.07C5.06 14.9 4.68 14.69 4.31 14.45C3.89 14.18 3.5 13.86 3.14 13.5C2.78 13.14 2.46 12.75 2.19 12.33C1.95 11.96 1.74 11.58 1.57 11.17C1.4 10.77 1.27 10.37 1.18 9.95C1.06 9.42 1 8.88 1 8.32C1 7.76 1.06 7.22 1.18 6.69C1.27 6.27 1.4 5.87 1.57 5.47C1.74 5.06 1.95 4.68 2.19 4.31C2.46 3.89 2.78 3.5 3.14 3.14C3.5 2.78 3.89 2.46 4.31 2.19C4.68 1.95 5.06 1.74 5.47 1.57C5.87 1.4 6.27 1.27 6.69 1.18C7.22 1.06 7.76 1 8.32 1C8.88 1 9.42 1.06 9.95 1.18C10.37 1.27 10.77 1.4 11.17 1.57C11.58 1.74 11.96 1.95 12.33 2.19C12.75 2.46 13.14 2.78 13.5 3.14C13.86 3.5 14.18 3.89 14.45 4.31C14.69 4.68 14.9 5.06 15.07 5.47C15.24 5.87 15.37 6.27 15.46 6.69C15.58 7.22 15.64 7.76 15.64 8.32C15.64 8.88 15.58 9.42 15.46 9.95C15.37 10.37 15.24 10.77 15.07 11.17C14.9 11.58 14.69 11.96 14.45 12.33C14.18 12.75 13.86 13.14 13.5 13.5C13.14 13.86 12.75 14.18 12.33 14.45C11.96 14.69 11.58 14.9 11.17 15.07C10.77 15.24 10.37 15.37 9.95 15.46Z"
              fill="#333333"
              fill-opacity="1.000000"
              fill-rule="evenodd"
            ></path>
            <path
              id="x"
              d="M14.94 13.7C14.75 13.5 14.44 13.5 14.24 13.7C14.04 13.89 14.04 14.2 14.24 14.4L20.18 20.35L20.54 20.7L21.24 20L20.89 19.64L20.89 19.64L14.94 13.7Z"
              fill="#333333"
              fill-opacity="1.000000"
              fill-rule="evenodd"
            ></path>
          </svg>
          <div class="btn-search-cont">
            <div class="inp">
              <input type="text" class="inp" placeholder="Search the Site" />
            </div>
            <div class="btn-handle">
              <div class="btn submit-btn top-button">
                <img src="../assets/images/duigou.svg" alt="" class="icon" />
              </div>
              <div class="btn close-btn top-button">
                <img src="../assets/images/close.svg" alt="" class="icon" />
              </div>
            </div>
          </div>
        </div>
        <div class="lang-switch pr-select">
          <div class="current">
            <img src="../assets/images/icon_global.svg" alt="" class="icon" />
          </div>
          <div class="pr-option-list">
            <div class="pr-option" @click="lang">CN</div>
          </div>
        </div>
      </div>
    </div>
    <a href="/" class="site-logo">
      <img src="../assets/images/logo.svg" alt="" data-type="notchange" />
    </a>
    <div class="btn-large-nav-expand top-button">
      <i></i>
      <i></i>
      <i></i>
    </div>
    <div class="large-nav">
      <div class="left">
        <div class="top">
          <!-- <a href="/" class="site-logo-inner">
            <img src="/themes/basic/skin/images/global/logo.svg" alt="">
            <img src="/themes/basic/skin/images/global/logo.svg" alt="" class="dark">
          </a> -->
        </div>
        <div class="bottom">
          <router-link to="/about" class="large-nav-tab" data-refer="3"
            >About</router-link
          >
          <router-link to="/modalities" class="large-nav-tab" data-refer="1"
            >Modalities</router-link
          >
          <router-link to="/services" class="large-nav-tab" data-refer="2"
            >Services</router-link
          >
          <router-link to="/news_knowledge" class="large-nav-tab" data-refer="4"
            >News & Knowledge Center</router-link
          >
          <router-link to="/join" class="large-nav-tab not-sub" data-refer="5"
            >Join</router-link
          >
          <router-link
            to="/contacts"
            class="large-nav-tab not-sub"
            data-refer="6"
            >Contacts</router-link
          >
        </div>
      </div>
      <div class="right">
        <div class="top">
          <!--<div class="search-box">
                  <input type="text" class="search-input" placeholder="Search the Site">
                  <button class="btn-search-submit top-button">
                      <i></i>
                  </button>
              </div>-->
        </div>
        <div class="bottom">
          <div class="large-nav-title">Modalities</div>
          <div class="large-nav-content"></div>
        </div>
      </div>
    </div>
    <div class="layer-mask"></div>
    <div class="loading-layer">
      <div class="loading-ring"></div>
    </div>
    <div class="cookie-alert">
      <div class="col-desc">
        <h1>We value your privacy!</h1>
        <p>
          We and our partners use technologies like cookies to process personal
          data such as IP addresses or browser information, so that you can see
          personalized advertisements. You can click the settings button in the
          bottom left corner of the page at any time to change or withdraw your
          consent.
        </p>
      </div>
      <div class="btn-ctn flex-aic">
        <div class="learn-more flex-center" @click="acceptCookies(1)">
          Accept all Cookie
        </div>
        <div class="learn-more flex-center" @click="acceptCookies(-1)">
          Partial acceptance
        </div>
        <div class="learn-more flex-center" @click="rejectCookies">
          Reject all
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    rejectCookies() {
      document.querySelector(".cookie-alert").classList.remove("show");
    },
    acceptCookies(type) {
      localStorage.cookiesAccepted = type;
      location.reload();
    },
    lang() {
      var link = '/cn'+this.$router.currentRoute.path;
      if (link == "/cn/news_knowledge/news_detail") {
        link = "/cn/news_knowledge/news";
      }
      if (link == "/cn/news_knowledge/knowledge_center_detail") {
        link = "/cn/news_knowledge/knowledge_center";
      }
      window.location.href = link;
    },
  },
  mounted() {
    if (localStorage.cookiesAccepted === undefined) {
      document.querySelector(".cookie-alert").classList.add("show");
    }

    $(".btn-large-nav-expand").click(function () {
      let $body = $("body");
      if ($body.hasClass("large-nav-active")) {
        if (window.innerWidth <= 750) {
          if ($(".large-nav>.right").hasClass("active")) {
            $(".large-nav>.right").removeClass("active");
          } else {
            $body.removeClass("large-nav-active");
          }
        } else {
          $body.removeClass("large-nav-active");
        }
        $body.removeClass("masked");
        $(".large-nav>.right").removeClass("active");
      } else {
        $body.addClass("large-nav-active");
        $body.addClass("masked");
      }
    });

    let $title = $(".large-nav-title");
    let $content = $(".large-nav-content");

    $title.click(function (e) {
      if (window.innerWidth <= 750) {
        location.href = $(this).attr("data-url");
      }
    });

    $(".large-nav-tab")
      .mouseenter(function () {
        let $tab = $(this);
        $tab.addClass("active").siblings().removeClass("active");
        let title = $tab.text().trim();
        let href = $tab.attr("href");
        let index = $tab.attr("data-refer");

        $content.empty();
        let $sub = $('.top-nav-sub[data-index="' + index + '"]');
        if ($sub.length) {
          $title.text(title);
          $title.attr("data-url", href);
          $sub.clone().removeClass("top-nav-sub").appendTo($content);
        } else {
          $title.text("");
          $title.attr("data-url", "");
        }
      })
      .eq(0)
      .trigger("mouseenter");

    $(".large-nav-tab").mouseenter(function (e) {
      if (window.innerWidth <= 750) {
        $(".large-nav>.right").addClass("active");
      }
    });

    $(".large-nav-tab").click(function (e) {
      if (window.innerWidth <= 750) {
        e.preventDefault();
      }
    });

    $(".lang-switch").hover(function () {
      $(this).toggleClass("active");
    });

    $(".btn-search-top .open-search").click(function (e) {
      e.stopPropagation();
      $(this).parent().toggleClass("active");
    });

    $(".btn-search-top .close-btn").click(function (e) {
      e.stopPropagation();
      $(this).parents(".btn-search-top").removeClass("active");
    });

    $(window).on("scroll", function () {
      smoother();
    });

    smoother();

    function smoother() {
      if ($(window).scrollTop() <= $(".site-header-bar").height()) {
        $("body").removeClass("scrolled");
      } else {
        $("body").addClass("scrolled");
      }
      var anchor = $(".anchor-nav");
      if (anchor.length) {
        if (
          $(".anchor-nav").offset().top - $(window).scrollTop() <=
          $(".site-header-bar").height()
        ) {
          $(".site-header").fadeOut();
          $(".site-logo").fadeOut();
          $(".btn-large-nav-expand").fadeOut();
        } else {
          $(".site-header").fadeIn();
          $(".site-logo").fadeIn();
          $(".btn-large-nav-expand").fadeIn();
        }
      }
    }
  },
};
</script>