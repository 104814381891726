import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/swiper-bundle-8.4.7.min.css";
import "./assets/css/global.css";
import "./assets/css/media.css";
import "./assets/css/animate-common.css";
import ElementUI from "element-ui"; //引入element
import "element-ui/lib/theme-chalk/index.css"; //引入element样式

import globalFn from "./assets/js/global";
import svgFn from "./assets/js/svg_morph";

import globalVariable from "@/assets/js/globalVariable";
Vue.prototype.$globalVariable = globalVariable;

ElementUI.MessageBox.setDefaults({ closeOnClickModal: false }); //全局禁止messageBox点击空白关闭
Vue.use(ElementUI); //全局使用element

Vue.prototype.$globalFn = globalFn;
Vue.prototype.$svgFn = svgFn;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
